import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {goBack, goTo} from '@/utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ScrollView, View} from 'react-native';
import {
  BalanceListItem,
  PayMethod,
  getBalanceList,
  getPayMethod,
  goIncome,
  paySuccess,
} from './recharge.service';
import Spin from '@/components/basic/spin';
import {Success, upiPayment} from '@/utils';
import RechargeBalance from './recharge-balance';
import RechargeSelect from './recharge-select';
import RechargeChannel from './recharge-channel';
import RechargeButton from '@/components/business/recharge-button';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {useFocusEffect} from '@react-navigation/native';
import {getBalance} from '@/services/global.service';
import {useScreenSize} from '../hooks/size.hooks';
import RechargeTip from './recharge-tip';
import {getGiveSentStrs, getTotalSentPriceStr} from './recharge.utils';
import {homePage} from '@/config';

const Recharge = () => {
  const {i18n} = useTranslation();
  const [balanceList, setBalanceList] = React.useState<BalanceListItem[]>([]);
  const [paymethodList, setPaymenthodList] = React.useState<PayMethod[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [balance, setBalance] = React.useState('');
  const {calcActualSize} = useScreenSize();
  const [payMethodId, setPayMethodId] = React.useState<number>();
  const payMethodItem = useMemo(() => {
    return paymethodList.find(p => p.id === payMethodId);
  }, [paymethodList, payMethodId]);
  const canShowBalanceList = useMemo(() => {
    return balanceList.filter(
      _balance =>
        _balance.balance >= (payMethodItem?.minAmount || 0) &&
        _balance.balance <=
          (payMethodItem?.maxAmount || Number.MAX_SAFE_INTEGER),
    );
  }, [balanceList, payMethodItem]);
  const balanceId = useMemo(() => {
    const item = canShowBalanceList.find(b => b.balance === +balance);
    return item ? item.id + '' : '';
  }, [canShowBalanceList, balance]);
  const [amount, setAmount] = useState<number>(0);
  const [incomeInfo, setIncomeInfo] = React.useState({
    upiId: '',
    orderNo: '',
  });

  const sortedBalanceIndexList = useMemo(() => {
    const sortedList = Array(canShowBalanceList?.length)
      .fill(0)
      .map((_, i) => i);
    sortedList.sort(
      (a, b) =>
        (canShowBalanceList?.[a].balance || 0) -
        (canShowBalanceList?.[b].balance || 0),
    );
    return sortedList;
  }, [canShowBalanceList]);

  const extraBounsBalanceIndex = useMemo(() => {
    if (canShowBalanceList == null || canShowBalanceList.length <= 0) {
      return -1;
    }
    if (sortedBalanceIndexList.length <= 0) {
      return -1;
    }
    if (+balance < canShowBalanceList[sortedBalanceIndexList[0]].balance) {
      return -1;
    }
    let currentSortBalanceIndex = -1;
    for (let i = 0; i < sortedBalanceIndexList.length; i++) {
      if (+balance >= canShowBalanceList[sortedBalanceIndexList[i]].balance) {
        currentSortBalanceIndex = sortedBalanceIndexList[i];
      } else {
        break;
      }
    }
    return currentSortBalanceIndex;
  }, [canShowBalanceList, sortedBalanceIndexList, balance]);

  const extraSent = useMemo(() => {
    if (!payMethodItem) {
      return 0;
    }

    return getGiveSentStrs(
      payMethodItem,
      canShowBalanceList[extraBounsBalanceIndex],
    );
  }, [canShowBalanceList, extraBounsBalanceIndex, payMethodItem]);

  const extraPriceStr = useMemo(() => {
    if (!payMethodItem) {
      return 0;
    }
    return getTotalSentPriceStr(
      balance,
      payMethodItem,
      canShowBalanceList[extraBounsBalanceIndex],
    );
  }, [balance, canShowBalanceList, extraBounsBalanceIndex, payMethodItem]);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([getBalanceList(), getPayMethod()])
      .then(([blance, paymenthod]) => {
        setBalanceList(blance);
        // setBalance((blance[0]?.balance || '') + '');
        setPaymenthodList(paymenthod);
        setPayMethodId(paymenthod[0].id);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setBalance((canShowBalanceList[0]?.balance || '') + '');
  }, [canShowBalanceList]);

  const handleRefresh = useCallback(() => {
    if (!globalStore.token) {
      goTo('Login', {backPage: homePage});
      return;
    }
    setLoading(true);
    getBalance('')
      .then(res => {
        setAmount(res.mainBalance);
      })
      .finally(() => {
        setLoading(false);
      });
    // globalStore.updateAmount.next({});
  }, []);

  useFocusEffect(handleRefresh);

  const handleGotoRecords = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: homePage});
      return;
    }
    goTo('RechargeRecords');
  };

  // 支付成功回调
  const onSuccess = (success: Success) => {
    if (success.status === 'SUCCESS') {
      paySuccess({
        orderNo: incomeInfo.orderNo,
        tradeResult: '1',
        approvalUrt: success.approvalRefNo,
      })
        .then()
        .finally(() => {
          globalStore.updateAmount.next({});
        });
    } else {
      // crashlytics().recordError(
      //   new Error(
      //     JSON.stringify({
      //       type: '支付成功,但状态不是success',
      //       incomeInfo: incomeInfo,
      //       successCB: success,
      //     }),
      //   ),
      // );
    }
  };
  const onFailure = (error: Error) => {
    globalStore.globalWaringTotal(error.message);
  };

  const handleRecharge = async () => {
    const {minAmount, maxAmount, payTag} = payMethodItem || {};

    if (
      !balance ||
      +balance <= 0 ||
      (minAmount && +balance < minAmount) ||
      (maxAmount && +balance > maxAmount)
    ) {
      globalStore.globalWaringTotal(i18n.t('recharge-page.tip.money-error'));
      return;
    }
    if (!payMethodItem) {
      globalStore.globalWaringTotal(
        i18n.t('recharge-page.tip.paymethod-error'),
      );
      return;
    }
    setLoading(true);
    goIncome({
      balanceId: balanceId || 0,
      payTag: payMethodItem.payTag,
      payTypeId: payMethodId + '',
      rechargeBalance: balanceId ? 0 : balance,
    })
      .then(res => {
        if (payTag == 'IM_RECHARGE') {
          goTo('Index', {
            screen: 'Chat',
            params: {
              service: `${Date.now()}`,
              amount: balance,
            },
          });
          return;
        }
        if (typeof res === 'string') {
          if (res.indexOf('#amount#') > -1) {
            res = res.replace('#amount#', balance);
          }
          if (res.indexOf('?') > -1) {
            res += '&lang=' + globalStore.lang;
          } else {
            res += '?lang=' + globalStore.lang;
          }
          if (globalStore.isWeb) {
            location.href = res;
          } else {
            goTo('WebView', {
              originUrl: res,
              header: true,
              headerTitle: i18n.t('label.recharge'),
              serverRight: true,
              hideAmount: true,
            });
          }
        } else {
          setIncomeInfo(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // 吊起APP支付
  const onPay = () => {
    const config = {
      payeeVpa: incomeInfo.upiId,
      payeeName: incomeInfo.upiId,
      merchantCode: incomeInfo.upiId,
      transactionId: incomeInfo.orderNo,
      transactionRefId: incomeInfo.orderNo,
      description: incomeInfo.orderNo,
      amount: balance + '',
    };
    upiPayment.initiate('net.one97.paytm', '', config, onSuccess, onFailure);
  };

  React.useEffect(() => {
    if (incomeInfo.orderNo && incomeInfo.upiId) {
      onPay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeInfo]);

  return (
    <View style={[theme.fill.fill, theme.flex.col, theme.background.lightGrey]}>
      <DetailNavTitle
        onBack={goBack}
        serverRight
        hideAmount
        title={i18n.t('label.recharge')}
      />
      <Spin loading={loading} style={[theme.flex.flex1, theme.flex.col]}>
        <View
          style={[
            theme.flex.flex1,
            theme.flex.basis0,
            {
              paddingBottom: calcActualSize(theme.paddingSize.s),
            },
          ]}>
          <ScrollView
            contentContainerStyle={[
              {
                paddingTop: calcActualSize(theme.paddingSize.l),
                paddingHorizontal: calcActualSize(theme.paddingSize.l),
                gap: calcActualSize(theme.paddingSize.l),
              },
            ]}>
            <RechargeBalance
              balance={amount}
              payMethod={payMethodItem?.payName}
              onRefresh={handleRefresh}
              onGotoRecords={handleGotoRecords}
            />
            <RechargeSelect
              min={payMethodItem?.minAmount || 0}
              max={payMethodItem?.maxAmount || 0}
              balance={balance}
              balanceList={canShowBalanceList}
              payMethod={payMethodItem}
              extraBounsBalanceIndex={extraBounsBalanceIndex}
              onChangeBalance={setBalance}
            />
            <RechargeChannel
              payMethodList={paymethodList}
              onPayMethodChange={setPayMethodId}
              payMethodId={payMethodId}
            />
          </ScrollView>
        </View>
        <RechargeButton
          type="linear-primary"
          disabled={balance === '' || +balance <= 0}
          onRecharge={handleRecharge}
          receiveTip={
            extraSent ? (
              <RechargeTip
                style={{left: calcActualSize(149), right: 'auto'}}
                animateType="95">
                {i18n.t('recharge.tip.extra', {
                  bonus: extraSent,
                })}
              </RechargeTip>
            ) : undefined
          }
          text={
            extraPriceStr
              ? i18n.t('label.rechargePrice', {price: extraPriceStr})
              : undefined
          }
        />
      </Spin>
    </View>
  );
};

export default Recharge;
